// SiteBottonNavigationTitle
// 用于存储底部导航栏的标题

import { defineStore } from "pinia";
export const useSiteBottonNavigationTitleStore = defineStore(
  "SiteBottonNavigationTitle",
  {
    state: () => {
      return {
        title: "42KIT",
      };
    },
    actions: {
      setTitle(title: string) {
        this.title = title;
      },
    },
  },
);
